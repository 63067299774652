import * as React from 'react';
import './toolbar.css';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  params?: any;
}

const models = [
  '',
  'ff001-starstruck',
  'ff001-starstruck-lines',
  'ff002-carnival',
  'ff003-viking',
  'ff004-china',
  'ff005-mexico',
  'ff006-retro-future',
  'ff007-greek',
  'ff008-egypt',
  'ff009-mad-scientist',
  // 'aff',
  // 'bff',
  // 'cff',
  // 'dff',
];
export const Toolbar = (props: IProps) => {
  let { modelId } = useParams<"modelId">();
  const [mId, setMId] = React.useState(modelId);
  const navigate = useNavigate();

  const choose = (e: any) => {
    navigate(`/${e.currentTarget.value}`);
    setMId(e.currentTarget.value);
  }

  return (<div className={`toolbar`}>
    <div className='logo'>
      <span className="icon-fezuone-logo-v"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
    </div>
    <select value={mId} onChange={(e) => choose(e)}>
      {models.map((it, i) => <option key={`${it}`} value={it}>{it}</option>)}
    </select>
    <div style={{flexGrow: 1}}></div>
    
  </div>)
}
